var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shimmer-card-container" },
    [
      _c(
        "carousel-component",
        {
          attrs: {
            id: "shimmer-carousel",
            "navigation-enabled": "hide",
            "pagination-enabled": "hide",
            "slides-count": _vm.shimmerCountInt + 1,
            "slides-per-page": _vm.shimmerCountInt,
          },
        },
        _vm._l(_vm.shimmerCountInt, function (count) {
          return _c(
            "slide",
            {
              key: count,
              attrs: { slot: `slide-${count}` },
              slot: `slide-${count}`,
            },
            [
              _c("div", { staticClass: "shimmer-card" }, [
                _c("div", { staticClass: "shimmer-wrapper" }, [
                  _c("div", { staticClass: "shimmer-product-image animate" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "comment animate" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "comment animate" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "comment animate" }),
                ]),
              ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }