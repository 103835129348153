var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `quantity control-group ${
        _vm.errors.has(_vm.controlName) ? "has-error" : ""
      }`,
    },
    [
      _c("label", {
        staticClass: "required",
        attrs: { for: "quantity-changer" },
        domProps: { textContent: _vm._s(_vm.quantityText) },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-btn-group" }, [
        _c(
          "button",
          {
            staticClass: "decrease",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.decreaseQty()
              },
            },
          },
          [_c("i", { staticClass: "rango-minus" })]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: _vm.validations,
              expression: "validations",
            },
          ],
          ref: "quantityChanger",
          staticClass: "control",
          attrs: {
            name: _vm.controlName,
            model: _vm.qty,
            id: "quantity-changer",
            "data-vv-as": `"${_vm.quantityText}"`,
          },
          on: {
            keyup: function ($event) {
              return _vm.setQty($event)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "increase",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.increaseQty()
              },
            },
          },
          [_c("i", { staticClass: "rango-plus" })]
        ),
      ]),
      _vm._v(" "),
      _vm.errors.has(_vm.controlName)
        ? _c("span", { staticClass: "control-error" }, [
            _vm._v(_vm._s(_vm.errors.first(_vm.controlName))),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }