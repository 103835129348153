var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer-assets-container" }, [
    _vm.currentType == "video"
      ? _c("div", { staticClass: "video-container" }, [
          _c(
            "video",
            {
              key: _vm.activeImageVideoURL,
              attrs: { width: "100%", controls: "" },
            },
            [
              _c("source", {
                attrs: { src: _vm.activeImageVideoURL, type: "video/mp4" },
              }),
            ]
          ),
        ])
      : _c("div", { staticClass: "image-container" }, [
          _c("div", { staticClass: "magnifier" }, [
            _c("img", {
              class: [
                !_vm.isMobile()
                  ? "main-product-image"
                  : "vc-small-product-image",
              ],
              attrs: {
                src: _vm.activeImageVideoURL,
                "data-zoom-image": _vm.activeImageVideoURL,
              },
            }),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }