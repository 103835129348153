var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isModalOpen
    ? _c("div", { staticClass: "modal-parent scrollable" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c(
            "div",
            { staticClass: "modal-header" },
            [
              _vm._t("header", function () {
                return [
                  _vm._v("\n                Default header\n            "),
                ]
              }),
              _vm._v(" "),
              _c("i", {
                staticClass: "icon remove-icon",
                on: { click: _vm.closeModal },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _vm._t("body", function () {
                return [_vm._v("\n                Default body\n            ")]
              }),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }