var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMobile() && _vm.navContainer
    ? _c(
        "div",
        { key: _vm.key, staticClass: "nav-container scrollable" },
        [_vm._t("sidebar-body")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }