var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "i",
    {
      class: `material-icons ${_vm.addClass ? _vm.addClass : ""}`,
      on: {
        mouseover: function ($event) {
          _vm.isActive ? (_vm.isActive = !_vm.isActive) : ""
        },
        mouseout: function ($event) {
          _vm.active !== "" && !_vm.isActive
            ? (_vm.isActive = !_vm.isActive)
            : ""
        },
      },
    },
    [
      _vm._v(
        "\n\n    " +
          _vm._s(_vm.isActive ? "favorite_border" : "favorite") +
          "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }