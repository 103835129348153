var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status == "true"
    ? _c("div", { staticClass: "d-inline-block image-search-container" }, [
        _c("label", { attrs: { for: "image-search-container" } }, [
          _c("i", { staticClass: "icon camera-icon" }),
          _vm._v(" "),
          _c("input", {
            ref: "image_search_input",
            staticClass: "d-none",
            attrs: { type: "file", id: "image-search-container" },
            on: {
              change: function ($event) {
                return _vm.loadLibrary()
              },
            },
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "d-none",
            attrs: {
              id: "uploaded-image-url",
              src: _vm.uploadedImageUrl,
              alt: "",
              width: "20",
              height: "20",
            },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }