var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    { staticClass: "wishlist-btn unset", attrs: { href: _vm.src } },
    [
      _c("i", { staticClass: "material-icons" }, [_vm._v("favorite_border")]),
      _vm._v(" "),
      _vm.wishlistCount > 0
        ? _c("div", { staticClass: "badge-container" }, [
            _c("span", {
              staticClass: "badge",
              domProps: { textContent: _vm._s(_vm.wishlistCount) },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isText == "true"
        ? _c("span", {
            domProps: { textContent: _vm._s(_vm.__("header.wishlist")) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }