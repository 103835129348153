var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid hot-categories-container" },
    [
      _c("card-list-header", { attrs: { heading: _vm.heading } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.categories, function (category, index) {
          return _c("hot-category", { key: index, attrs: { slug: category } })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }