var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { method: "POST" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.addToCart.apply(null, arguments)
        },
      },
    },
    [
      _vm.moveToCart
        ? _c(
            "a",
            {
              class: `btn btn-add-to-cart ${_vm.addClassToBtn}`,
              attrs: {
                href: _vm.wishlistMoveRoute,
                disabled:
                  _vm.isButtonEnable == "false" || _vm.isButtonEnable == false,
              },
            },
            [
              _vm.showCartIcon
                ? _c("i", { staticClass: "material-icons text-down-3" }, [
                    _vm._v("shopping_cart"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", {
                staticClass: "fs14 fw6 text-uppercase text-up-4",
                domProps: { textContent: _vm._s(_vm.btnText) },
              }),
            ]
          )
        : _c(
            "button",
            {
              class: `btn btn-add-to-cart ${_vm.addClassToBtn}`,
              attrs: {
                type: "submit",
                disabled:
                  _vm.isButtonEnable == "false" || _vm.isButtonEnable == false,
              },
            },
            [
              _vm.showCartIcon
                ? _c("i", { staticClass: "material-icons text-down-3" }, [
                    _vm._v("shopping_cart"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", {
                staticClass: "fs14 fw6 text-uppercase text-up-4",
                domProps: { textContent: _vm._s(_vm.btnText) },
              }),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }