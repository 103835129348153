var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "quick-view-btn-container",
      attrs: { id: "quick-view-btn-container" },
      on: {
        click: function ($event) {
          return _vm.openQuickView({ event: $event })
        },
      },
    },
    [
      _c("span", { staticClass: "rango-zoom-plus" }),
      _vm._v(" "),
      _c("button", { attrs: { type: "button" } }, [
        _vm._v(_vm._s(_vm.__("products.quick-view"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }