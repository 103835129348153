var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hotCategoryDetails
    ? _c("div", { staticClass: "col-lg-3 col-md-12 hot-category-wrapper" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "row velocity-divide-page" }, [
            _c("div", { staticClass: "left" }, [
              _c("img", {
                attrs: {
                  src: _vm.hotCategoryDetails.category_icon_url,
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [
              _c("h3", { staticClass: "fs20 clr-light text-uppercase" }, [
                _c("a", { staticClass: "unset", attrs: { href: "${slug}" } }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.hotCategoryDetails.name) +
                      "\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { attrs: { type: "none" } },
                _vm._l(
                  _vm.hotCategoryDetails.children,
                  function (subCategory, index) {
                    return _c("li", { key: index }, [
                      _c(
                        "a",
                        {
                          staticClass: "remove-decoration normal-text",
                          attrs: { href: `${_vm.slug}/${subCategory.slug}` },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(subCategory.name) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ])
                  }
                ),
                0
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }