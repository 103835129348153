var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `main-category fs16 unselectable fw6 ${
        _vm.$root.sharedRootCategories.length > 0
          ? "cursor-pointer"
          : "cursor-not-allowed"
      } left`,
      attrs: { id: "main-category" },
      on: {
        mouseout: function ($event) {
          return _vm.toggleSidebar("0", $event, "mouseout")
        },
        mouseover: function ($event) {
          return _vm.toggleSidebar("0", $event, "mouseover")
        },
      },
    },
    [
      _c("i", { staticClass: "rango-view-list align-vertical-top fs18" }),
      _vm._v(" "),
      _c("span", {
        staticClass: "pl5",
        domProps: { textContent: _vm._s(_vm.heading) },
        on: {
          mouseover: function ($event) {
            return _vm.toggleSidebar("0", $event, "mouseover")
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }