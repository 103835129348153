var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a", {
    class: _vm.addClass,
    attrs: { href: _vm.href },
    domProps: { textContent: _vm._s(_vm.text) },
    on: {
      click: function ($event) {
        return _vm.checkMinimumOrder($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }