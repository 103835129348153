var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-parent scrollable" }, [
    _c(
      "div",
      { staticClass: "cd-quick-view" },
      [
        _vm.showProductDetails || true
          ? [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "ul",
                    { staticClass: "cd-slider", attrs: { type: "none" } },
                    [
                      _c(
                        "carousel-component",
                        {
                          attrs: {
                            "slides-per-page": "1",
                            "navigation-enabled": "hide",
                            "slides-count": _vm.product.galleryImages.length,
                          },
                        },
                        _vm._l(
                          _vm.product.galleryImages,
                          function (image, index) {
                            return _c(
                              "slide",
                              {
                                key: index,
                                attrs: { slot: `slide-${index}`, title: " " },
                                slot: `slide-${index}`,
                              },
                              [
                                _c(
                                  "li",
                                  {
                                    staticClass: "selected",
                                    on: {
                                      click: function ($event) {
                                        _vm.showProductDetails = false
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: image.medium_image_url,
                                        alt: _vm.product.name,
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6 fs16" }, [
                  _c("h2", { staticClass: "fw6 quick-view-name" }, [
                    _vm._v(_vm._s(_vm.product.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "product-price",
                    domProps: { innerHTML: _vm._s(_vm.product.priceHTML) },
                  }),
                  _vm._v(" "),
                  _vm.product.totalReviews && _vm.product.totalReviews > 0
                    ? _c(
                        "div",
                        { staticClass: "product-rating" },
                        [
                          _c("star-ratings", {
                            attrs: { ratings: _vm.product.avgRating },
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "pl10 unset active-hover",
                              attrs: {
                                href: `${_vm.$root.baseUrl}/reviews/${_vm.product.slug}`,
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.__("products.reviews-count", {
                                      totalReviews: _vm.product.totalReviews,
                                    })
                                  ) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "product-rating" }, [
                        _c("span", {
                          staticClass: "fs14",
                          domProps: {
                            textContent: _vm._s(_vm.product.firstReviewText),
                          },
                        }),
                      ]),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "pt14 fs14 description-text",
                    domProps: {
                      innerHTML: _vm._s(_vm.product.shortDescription),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "product-actions" },
                    [
                      _c("vnode-injector", {
                        attrs: {
                          nodes: _vm.getDynamicHTML(_vm.product.addToCartHtml),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "close-btn rango-close fs18 cursor-pointer",
                on: { click: _vm.closeQuickView },
              }),
            ]
          : [
              _c("div", { staticClass: "product-gallery" }, [
                _c(
                  "ul",
                  { staticClass: "cd-slider", attrs: { type: "none" } },
                  [
                    _c(
                      "carousel-component",
                      {
                        attrs: {
                          "slides-per-page": "1",
                          "navigation-enabled": "hide",
                          "slides-count": _vm.product.galleryImages.length,
                        },
                      },
                      _vm._l(
                        _vm.product.galleryImages,
                        function (image, index) {
                          return _c(
                            "slide",
                            {
                              key: index,
                              attrs: { slot: `slide-${index}` },
                              slot: `slide-${index}`,
                            },
                            [
                              _c("li", { staticClass: "selected" }, [
                                _c("img", {
                                  attrs: {
                                    src: image.medium_image_url,
                                    alt: _vm.product.name,
                                  },
                                }),
                              ]),
                            ]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "close-btn rango-close fs18 cursor-pointer",
                on: {
                  click: function ($event) {
                    _vm.showProductDetails = true
                  },
                },
              }),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }