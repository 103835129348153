import { render, staticRenderFns } from "./mini-cart.vue?vue&type=template&id=b8ca27aa"
import script from "./mini-cart.vue?vue&type=script&lang=js"
export * from "./mini-cart.vue?vue&type=script&lang=js"
import style0 from "./mini-cart.vue?vue&type=style&index=0&id=b8ca27aa&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\laragon\\www\\ciakitdev3\\ciakit\\laravel\\packages\\Webkul\\Velocity\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b8ca27aa')) {
      api.createRecord('b8ca27aa', component.options)
    } else {
      api.reload('b8ca27aa', component.options)
    }
    module.hot.accept("./mini-cart.vue?vue&type=template&id=b8ca27aa", function () {
      api.rerender('b8ca27aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Resources/assets/js/UI/components/mini-cart.vue"
export default component.exports