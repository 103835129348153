var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      class: `left ${_vm.addClass}`,
      attrs: { href: _vm.redirectSrc, "aria-label": "Logo" },
    },
    [_c("img", { staticClass: "logo", attrs: { src: _vm.imgSrc, alt: "" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }