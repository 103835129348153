var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-8" }, [
      _c(
        "div",
        { staticClass: "flex gap-4" },
        [
          _vm.hamburger
            ? _c("div", { staticClass: "nav-container scrollable" }, [
                this.rootCategories
                  ? _c(
                      "div",
                      { staticClass: "wrapper" },
                      [
                        _c(
                          "div",
                          { staticClass: "greeting drawer-section fw6" },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("perm_identity"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              [
                                _vm._t("greetings"),
                                _vm._v(" "),
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons text-dark float-right",
                                    on: {
                                      click: function ($event) {
                                        return _vm.closeDrawer()
                                      },
                                    },
                                  },
                                  [_vm._v(" cancel ")]
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.headerContent.length > 0
                          ? _c(
                              "ul",
                              {
                                staticClass: "velocity-content",
                                attrs: { type: "none" },
                              },
                              _vm._l(
                                _vm.headerContent,
                                function (content, index) {
                                  return _c("li", { key: index }, [
                                    _c("a", {
                                      staticClass: "unset",
                                      attrs: {
                                        href: `${_vm.$root.baseUrl}/${content.page_link}`,
                                      },
                                      domProps: {
                                        textContent: _vm._s(content.title),
                                      },
                                    }),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.rootCategoriesCollection.length > 0
                          ? _c(
                              "ul",
                              {
                                staticClass: "category-wrapper",
                                attrs: { type: "none" },
                              },
                              _vm._l(
                                _vm.rootCategoriesCollection,
                                function (category, index) {
                                  return _c("li", { key: index }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "unset",
                                        attrs: {
                                          href: `${_vm.$root.baseUrl}/${category.slug}`,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "category-logo" },
                                          [
                                            category.category_icon_url
                                              ? _c("img", {
                                                  staticClass: "category-icon",
                                                  attrs: {
                                                    src: category.category_icon_url,
                                                    alt: "",
                                                    width: "20",
                                                    height: "20",
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(category.name),
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    category.children.length > 0
                                      ? _c("i", {
                                          staticClass: "rango-arrow-right",
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleSubcategories(
                                                index,
                                                $event
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._t("customer-navigation"),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass: "meta-wrapper",
                            attrs: { type: "none" },
                          },
                          [
                            _c(
                              "li",
                              [
                                _vm.locale
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "language-logo-wrapper",
                                        },
                                        [
                                          _vm.locale.image_url
                                            ? _c("img", {
                                                staticClass: "language-logo",
                                                attrs: {
                                                  src: _vm.locale.image_url,
                                                  alt: "",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.locale.name
                                            ),
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.locale.image_url))]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "rango-arrow-right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleMetaInfo("languages")
                                    },
                                  },
                                }),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("li", [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.currency.code),
                                },
                              }),
                              _vm._v(" "),
                              _c("i", {
                                staticClass: "rango-arrow-right",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleMetaInfo("currencies")
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _vm._t("extra-navigation"),
                          ],
                          2
                        ),
                      ],
                      2
                    )
                  : _vm.subCategory
                  ? _c("div", { staticClass: "wrapper" }, [
                      _c("div", { staticClass: "drawer-section" }, [
                        _c("i", {
                          staticClass: "rango-arrow-left fs24 text-down-4",
                          on: {
                            click: function ($event) {
                              return _vm.toggleSubcategories("root")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("h4", {
                          staticClass: "display-inbl",
                          domProps: {
                            textContent: _vm._s(_vm.subCategory.name),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons text-dark float-right",
                            on: {
                              click: function ($event) {
                                return _vm.closeDrawer()
                              },
                            },
                          },
                          [_vm._v(" cancel ")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { attrs: { type: "none" } },
                        _vm._l(
                          _vm.subCategory.children,
                          function (nestedSubCategory, index) {
                            return _c("li", { key: index }, [
                              _c(
                                "a",
                                {
                                  staticClass: "unset",
                                  attrs: {
                                    href: `${_vm.$root.baseUrl}/${_vm.subCategory.slug}/${nestedSubCategory.slug}`,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "category-logo" }, [
                                    nestedSubCategory.category_icon_url
                                      ? _c("img", {
                                          staticClass: "category-icon",
                                          attrs: {
                                            src: nestedSubCategory.category_icon_url,
                                            alt: "",
                                            width: "20",
                                            height: "20",
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        nestedSubCategory.name
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              nestedSubCategory.children &&
                              nestedSubCategory.children.length > 0
                                ? _c(
                                    "ul",
                                    {
                                      staticClass: "nested-category",
                                      attrs: { type: "none" },
                                    },
                                    _vm._l(
                                      nestedSubCategory.children,
                                      function (thirdLevelCategory, index) {
                                        return _c(
                                          "li",
                                          { key: `index-${index}` },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "unset",
                                                attrs: {
                                                  href: `${_vm.$root.baseUrl}/${_vm.subCategory.slug}/${nestedSubCategory.slug}/${thirdLevelCategory.slug}`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "category-logo",
                                                  },
                                                  [
                                                    thirdLevelCategory.category_icon_url
                                                      ? _c("img", {
                                                          staticClass:
                                                            "category-icon",
                                                          attrs: {
                                                            src: thirdLevelCategory.category_icon_url,
                                                            alt: "",
                                                            width: "20",
                                                            height: "20",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      thirdLevelCategory.name
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ])
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm.languages
                  ? _c("div", { staticClass: "wrapper" }, [
                      _c("div", { staticClass: "drawer-section" }, [
                        _c("i", {
                          staticClass: "rango-arrow-left fs24 text-down-4",
                          on: {
                            click: function ($event) {
                              return _vm.toggleMetaInfo("languages")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("h4", {
                          staticClass: "display-inbl",
                          domProps: {
                            textContent: _vm._s(
                              _vm.__("responsive.header.languages")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons text-dark float-right",
                            on: {
                              click: function ($event) {
                                return _vm.closeDrawer()
                              },
                            },
                          },
                          [_vm._v("cancel")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { attrs: { type: "none" } },
                        _vm._l(_vm.allLocales, function (locale, index) {
                          return _c("li", { key: index }, [
                            _c(
                              "a",
                              {
                                staticClass: "unset",
                                attrs: { href: `?locale=${locale.code}` },
                              },
                              [
                                _c("div", { staticClass: "category-logo" }, [
                                  locale.image_url
                                    ? _c("img", {
                                        staticClass: "category-icon",
                                        attrs: {
                                          src: locale.image_url,
                                          alt: "",
                                          width: "20",
                                          height: "20",
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(locale.name),
                                  },
                                }),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ])
                  : _vm.currencies
                  ? _c("div", { staticClass: "wrapper" }, [
                      _c("div", { staticClass: "drawer-section" }, [
                        _c("i", {
                          staticClass: "rango-arrow-left fs24 text-down-4",
                          on: {
                            click: function ($event) {
                              return _vm.toggleMetaInfo("currencies")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("h4", {
                          staticClass: "display-inbl",
                          domProps: {
                            textContent: _vm._s(
                              _vm.__("shop.general.currencies")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "i",
                          {
                            staticClass: "material-icons text-dark float-right",
                            on: {
                              click: function ($event) {
                                return _vm.closeDrawer()
                              },
                            },
                          },
                          [_vm._v("cancel")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { attrs: { type: "none" } },
                        _vm._l(_vm.allCurrencies, function (currency, index) {
                          return _c("li", { key: index }, [
                            _c(
                              "a",
                              {
                                staticClass: "unset",
                                attrs: { href: `?currency=${currency.code}` },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(currency.code),
                                  },
                                }),
                              ]
                            ),
                          ])
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "hamburger-wrapper",
              on: { click: _vm.toggleHamburger },
            },
            [_c("i", { staticClass: "rango-toggle hamburger" })]
          ),
          _vm._v(" "),
          _vm._t("logo"),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-vc-header col-4" },
      [
        _vm._t("top-header"),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "unset cursor-pointer",
            on: { click: _vm.openSearchBar },
          },
          [_c("i", { staticClass: "material-icons" }, [_vm._v("search")])]
        ),
        _vm._v(" "),
        _c("a", { staticClass: "unset", attrs: { href: _vm.cartRoute } }, [
          _c("i", { staticClass: "material-icons text-down-3" }, [
            _vm._v("shopping_cart"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "badge-wrapper" }, [
            _c("span", {
              staticClass: "badge",
              domProps: { textContent: _vm._s(_vm.updatedCartItemsCount) },
            }),
          ]),
        ]),
      ],
      2
    ),
    _vm._v(" "),
    _vm.isSearchbar
      ? _c("div", { staticClass: "right searchbar" }, [_vm._t("search-bar")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }