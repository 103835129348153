var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.addClass }, [
    _c("div", { staticClass: "row remove-padding-margin" }, [
      _c("div", { staticClass: "col-12 no-padding" }, [
        _c("h2", {
          staticClass: "fs20 fw6 mb15",
          domProps: { textContent: _vm._s(_vm.title) },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: `recently-viewed-products-wrapper ${_vm.addClassWrapper}` },
      [
        _vm._l(_vm.recentlyViewed, function (product, index) {
          return _c(
            "div",
            { key: Math.random(), staticClass: "row small-card-container" },
            [
              _c("div", { staticClass: "col-4 product-image-container mr15" }, [
                _c(
                  "a",
                  {
                    staticClass: "unset",
                    attrs: { href: `${_vm.baseUrl}/${product.urlKey}` },
                  },
                  [
                    _c("div", {
                      staticClass: "product-image",
                      style: `background-image: url(${product.image})`,
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              product.urlKey
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-8 no-padding card-body align-vertical-top",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "unset no-padding",
                          attrs: { href: `${_vm.baseUrl}/${product.urlKey}` },
                        },
                        [
                          _c("div", { staticClass: "product-name" }, [
                            _c("span", {
                              staticClass: "fs16 text-nowrap",
                              domProps: { textContent: _vm._s(product.name) },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "fs18 card-current-price fw6",
                            domProps: { innerHTML: _vm._s(product.priceHTML) },
                          }),
                          _vm._v(" "),
                          product.rating > 0
                            ? _c("star-ratings", {
                                attrs: {
                                  "push-class": "display-inbl",
                                  ratings: product.rating,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        }),
        _vm._v(" "),
        !_vm.recentlyViewed ||
        (_vm.recentlyViewed && Object.keys(_vm.recentlyViewed).length == 0)
          ? _c("span", {
              staticClass: "fs16",
              domProps: { textContent: _vm._s(_vm.noDataText) },
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }