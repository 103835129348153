var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$root.loading
    ? _c("div", { staticClass: "modal-parent" }, [
        _c("div", { staticClass: "overlay-loader" }, [
          _c("div", { staticClass: "cp-spinner cp-round" }),
          _vm._v(" "),
          _c("div", {
            staticClass: "message-block",
            domProps: {
              textContent: _vm._s(_vm.__("shop.overlay-loader.message")),
            },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }