var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: `btn btn-link disable-box-shadow ${
        _vm.itemCount == 0 ? "cursor-not-allowed" : ""
      }`,
      attrs: { type: "button", id: "mini-cart" },
      on: { click: _vm.toggleMiniCart },
    },
    [
      _c("div", { staticClass: "mini-cart-content" }, [
        _c("i", { staticClass: "material-icons-outlined text-down-3" }, [
          _vm._v("shopping_cart"),
        ]),
        _vm._v(" "),
        _vm.itemCount != 0
          ? _c("span", {
              staticClass: "badge",
              domProps: { textContent: _vm._s(_vm.itemCount) },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("span", {
          staticClass: "fs18 fw6 cart-text",
          domProps: { textContent: _vm._s(_vm.cartText) },
        }),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "down-arrow-container" }, [
      _c("span", { staticClass: "rango-arrow-down" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }