var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "qty-btn unselectable" }, [
    _c("div", {
      staticClass: "deduct-btn rango-minus",
      on: {
        click: function ($event) {
          _vm.count--
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "actual-count" }, [_vm._v(_vm._s(_vm.count))]),
    _vm._v(" "),
    _c("div", {
      staticClass: "increase-btn rango-plus",
      on: {
        click: function ($event) {
          _vm.count++
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }