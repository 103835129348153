var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _vm.isLoading
        ? _c("shimmer-component")
        : _vm.productCollections.length > 0
        ? [
            _c("card-list-header", {
              attrs: {
                heading: _vm.isCategory
                  ? _vm.categoryDetails.name
                  : _vm.productTitle,
                "view-all": _vm.isCategory
                  ? `${this.baseUrl}/${_vm.categoryDetails.url_path}`
                  : "",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row", class: _vm.localeDirection },
              [
                _c(
                  "div",
                  {
                    staticClass: "col-md-12 no-padding carousel-products",
                    class:
                      _vm.showRecentlyViewed === "true"
                        ? "with-recent-viewed col-lg-9"
                        : "without-recent-viewed col-lg-12",
                  },
                  [
                    _vm.count != 0
                      ? _c(
                          "carousel-component",
                          {
                            attrs: {
                              "slides-per-page": _vm.slidesPerPage,
                              "pagination-enabled": "hide",
                              id: _vm.isCategory
                                ? `${_vm.categoryDetails.name}-carousel`
                                : _vm.productId,
                              "locale-direction": _vm.localeDirection,
                              "slides-count": _vm.productCollections.length,
                            },
                          },
                          _vm._l(
                            _vm.productCollections,
                            function (product, index) {
                              return _c(
                                "slide",
                                {
                                  key: index,
                                  attrs: { slot: `slide-${index}` },
                                  slot: `slide-${index}`,
                                },
                                [
                                  _c("product-card", {
                                    attrs: { list: _vm.list, product: product },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showRecentlyViewed === "true"
                  ? _c("recently-viewed", {
                      attrs: {
                        title: _vm.recentlyViewedTitle,
                        "no-data-text": _vm.noDataText,
                        "add-class": `col-lg-3 col-md-12 ${_vm.localeDirection}`,
                        quantity: "3",
                        "add-class-wrapper": "",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }