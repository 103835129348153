var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.subCategories,
          expression: "subCategories",
        },
      ],
      staticClass: "submenu-container",
      attrs: { id: "sub-categories" },
    },
    [
      _c(
        "div",
        { staticClass: "submenu-content-container" },
        [
          _c("sidebar-component", {
            attrs: { url: _vm.url, categories: _vm.subCategories },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }