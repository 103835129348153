var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "no-margin", attrs: { type: "none" } },
    _vm._l(_vm.headerContent, function (content, index) {
      return _c("li", { key: index }, [
        content["content_type"] == "link" ||
        content["content_type"] == "category"
          ? _c("a", {
              attrs: {
                href: `${_vm.$root.baseUrl}/${content["page_link"]}`,
                target: content["link_target"] ? "_blank" : "_self",
              },
              domProps: { textContent: _vm._s(content.title) },
            })
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }