var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "carousel",
    {
      class: [
        _vm.localeDirection,
        _vm.navigationEnabled == "hide" ? "navigation-hide" : "",
        _vm.paginationEnabled == "hide" ? "pagination-hide" : "",
        _vm.addClass,
      ],
      attrs: {
        "mouse-drag": true,
        rtl: _vm.localeDirection == "rtl",
        dir: _vm.localeDirection,
        id: _vm.id,
        navigationEnabled: true,
        navigationPrevLabel: "<span class='rango-arrow-left'></span>",
        navigationNextLabel: "<span class='rango-arrow-right'></span>",
        paginationEnabled: false,
        perPage: parseInt(_vm.slidesPerPage),
        loop: _vm.loop == "true" ? true : false,
        autoplay: _vm.autoplay == "true" ? true : false,
        autoplayTimeout: _vm.timeout ? parseInt(_vm.timeout) : 2000,
        autoplayDirection: "forward",
      },
    },
    [
      _vm._l(parseInt(_vm.slidesCount), function (index) {
        return _vm._t(`slide-${parseInt(index) - 1}`)
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }