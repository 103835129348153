var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.popularCategoryDetails
    ? _c(
        "div",
        { staticClass: "col-lg-3 col-md-12 popular-category-wrapper" },
        [
          _c("div", { staticClass: "card col-12 no-padding" }, [
            _c("div", { staticClass: "category-image" }, [
              _c("img", {
                staticClass: "lazyload",
                attrs: {
                  "data-src": _vm.popularCategoryDetails.image_url,
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-description" }, [
              _c("h3", { staticClass: "fs20" }, [
                _vm._v(_vm._s(_vm.popularCategoryDetails.name)),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "font-clr pl30" },
                _vm._l(
                  _vm.popularCategoryDetails.children,
                  function (subCategory, index) {
                    return _c("li", { key: index }, [
                      _c(
                        "a",
                        {
                          staticClass: "remove-decoration normal-text",
                          attrs: { href: `${_vm.slug}/${subCategory.slug}` },
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(subCategory.name) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ])
                  }
                ),
                0
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }