var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "unset compare-icon text-right",
      attrs: { title: `${_vm.addTooltip}` },
      on: { click: _vm.addProductToCompare },
    },
    [_c("i", { staticClass: "material-icons" }, [_vm._v("compare_arrows")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }