var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.list
    ? _c(
        "div",
        { staticClass: "col-12 lg-card-container list-card product-card row" },
        [
          _c("div", { staticClass: "product-image" }, [
            _c(
              "a",
              {
                attrs: {
                  title: _vm.product.name,
                  href: `${_vm.baseUrl}/${_vm.product.slug}`,
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: _vm.product.image || _vm.product.product_image,
                    onerror: `this.src='${this.$root.baseUrl}/vendor/webkul/ui/assets/images/product/large-product-placeholder.png'`,
                  },
                }),
                _vm._v(" "),
                !_vm.isMobile()
                  ? _c("product-quick-view-btn", {
                      attrs: { "quick-view-details": _vm.product },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "product-information" }, [
            _c(
              "div",
              [
                _c("div", { staticClass: "product-name" }, [
                  _c(
                    "a",
                    {
                      staticClass: "unset",
                      attrs: {
                        href: `${_vm.baseUrl}/${_vm.product.slug}`,
                        title: _vm.product.name,
                      },
                    },
                    [
                      _c("span", { staticClass: "fs16" }, [
                        _vm._v(_vm._s(_vm.product.name)),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.product.new
                  ? _c("div", { staticClass: "sticker new" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.product.new) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass: "product-price",
                  domProps: { innerHTML: _vm._s(_vm.product.priceHTML) },
                }),
                _vm._v(" "),
                _vm.product.totalReviews && _vm.product.totalReviews > 0
                  ? _c(
                      "div",
                      { staticClass: "product-rating" },
                      [
                        _c("star-ratings", {
                          attrs: { ratings: _vm.product.avgRating },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.__("products.reviews-count", {
                                totalReviews: _vm.product.totalReviews,
                              })
                            )
                          ),
                        ]),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "product-rating" }, [
                      _c("span", {
                        staticClass: "fs14",
                        domProps: {
                          textContent: _vm._s(_vm.product.firstReviewText),
                        },
                      }),
                    ]),
                _vm._v(" "),
                _c("vnode-injector", {
                  attrs: {
                    nodes: _vm.getDynamicHTML(_vm.product.addToCartHtml),
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      )
    : _c("div", { staticClass: "card grid-card product-card-new" }, [
        _c(
          "a",
          {
            staticClass: "product-image-container",
            attrs: {
              href: `${_vm.baseUrl}/${_vm.product.slug}`,
              title: _vm.product.name,
            },
          },
          [
            _c("img", {
              staticClass: "card-img-top lzy_img",
              attrs: {
                loading: "lazy",
                alt: _vm.product.name,
                src: _vm.product.image || _vm.product.product_image,
                "data-src": _vm.product.image || _vm.product.product_image,
                onerror: `this.src='${this.$root.baseUrl}/vendor/webkul/ui/assets/images/product/large-product-placeholder.png'`,
              },
            }),
            _vm._v(" "),
            _c("product-quick-view-btn", {
              attrs: { "quick-view-details": _vm.product },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("div", { staticClass: "product-name col-12 no-padding" }, [
              _c(
                "a",
                {
                  staticClass: "unset",
                  attrs: {
                    title: _vm.product.name,
                    href: `${_vm.baseUrl}/${_vm.product.slug}`,
                  },
                },
                [
                  _c("span", { staticClass: "fs16" }, [
                    _vm._v(_vm._s(_vm.product.name)),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.product.new
              ? _c("div", { staticClass: "sticker new" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.product.new) + "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", {
              domProps: { innerHTML: _vm._s(_vm.product.priceHTML) },
            }),
            _vm._v(" "),
            _vm.product.totalReviews && _vm.product.totalReviews > 0
              ? _c(
                  "div",
                  { staticClass: "product-rating col-12 no-padding" },
                  [
                    _c("star-ratings", {
                      attrs: { ratings: _vm.product.avgRating },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "fs14 align-top unset active-hover",
                        attrs: {
                          href: `${_vm.$root.baseUrl}/reviews/${_vm.product.slug}`,
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.__("products.reviews-count", {
                                totalReviews: _vm.product.totalReviews,
                              })
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "product-rating col-12 no-padding" }, [
                  _c("span", {
                    staticClass: "fs14",
                    domProps: {
                      textContent: _vm._s(_vm.product.firstReviewText),
                    },
                  }),
                ]),
            _vm._v(" "),
            _c("vnode-injector", {
              attrs: { nodes: _vm.getDynamicHTML(_vm.product.addToCartHtml) },
            }),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }