var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `slides-container ${_vm.direction}` },
    [
      _c(
        "carousel-component",
        {
          attrs: {
            loop: "true",
            timeout: "5000",
            autoplay: "true",
            "slides-per-page": "1",
            "navigation-enabled": "hide",
            paginationEnabled: "hide",
            "locale-direction": _vm.direction,
            "slides-count": _vm.banners.length > 0 ? _vm.banners.length : 1,
          },
        },
        [
          _vm.banners.length > 0
            ? _vm._l(_vm.banners, function (banner, index) {
                return _c(
                  "slide",
                  {
                    key: index,
                    attrs: { slot: `slide-${index}`, title: " " },
                    slot: `slide-${index}`,
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            banner.slider_path != ""
                              ? banner.slider_path
                              : "javascript:void(0);",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "col-12 no-padding banner-icon",
                          attrs: {
                            src:
                              banner.image_url != ""
                                ? banner.image_url
                                : _vm.defaultBanner,
                          },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "show-content",
                          domProps: {
                            innerHTML: _vm._s(
                              banner.content.replace("\r\n", "")
                            ),
                          },
                        }),
                      ]
                    ),
                  ]
                )
              })
            : [
                _c("slide", { attrs: { slot: "slide-0" }, slot: "slide-0" }, [
                  _c("img", {
                    staticClass: "col-12 no-padding banner-icon",
                    attrs: { loading: "lazy", src: _vm.defaultBanner, alt: "" },
                  }),
                ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }