var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      [
        _c(
          "ul",
          { staticStyle: { margin: "1rem 0 0 0", padding: "0" } },
          _vm._l(_vm.media, function (image, index) {
            return _c("li", { key: index, staticClass: "tb-gallery" }, [
              _c("img", {
                attrs: { src: image.thumb },
                on: {
                  click: function ($event) {
                    return _vm.openGallery(index)
                  },
                },
              }),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("LightBox", {
          ref: "lightbox",
          attrs: {
            media: _vm.media,
            "show-caption": true,
            "show-light-box": false,
            nThumbs: 1,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }