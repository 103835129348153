var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-group full-width force-center bg-black" },
    [
      _c("div", { staticClass: "selectdiv" }, [
        _c(
          "select",
          {
            staticClass: "form-control fs13 styled-select hidden",
            attrs: { name: "category", "aria-label": "Category" },
            on: {
              change: function ($event) {
                return _vm.focusInput($event)
              },
            },
          },
          [
            _c("option", {
              attrs: { value: "" },
              domProps: {
                textContent: _vm._s(_vm.__("header.all-categories")),
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.$root.sharedRootCategories, function (category, index) {
              return [
                category.id == _vm.searchedQuery.category
                  ? _c("option", {
                      key: index,
                      attrs: { selected: "selected" },
                      domProps: {
                        value: category.id,
                        textContent: _vm._s(category.name),
                      },
                    })
                  : _c("option", {
                      key: index,
                      domProps: {
                        value: category.id,
                        textContent: _vm._s(category.name),
                      },
                    }),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model:value",
            value: _vm.inputVal,
            expression: "inputVal",
            arg: "value",
          },
        ],
        staticClass: "form-control",
        attrs: {
          required: "",
          name: "term",
          type: "search",
          placeholder: _vm.__("header.search-text"),
          "aria-label": "Search",
        },
        domProps: { value: _vm.inputVal },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.inputVal = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn",
          attrs: {
            type: "button",
            id: "header-search-icon",
            "aria-label": "Search",
          },
          on: { click: _vm.submitForm },
        },
        [_c("i", { staticClass: "fs16 fw6 rango-search" })]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "select-icon-container d-inline-block float-right hidden",
      },
      [_c("span", { staticClass: "select-icon rango-arrow-down hidden" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }