var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `row mb15 col-12 carousel-products-header ${_vm.rowClass}` },
    [
      _vm.tabs || _vm.viewAll || _vm.scrollable
        ? _c("div", { staticClass: "col-4 no-padding" }, [
            _c("h2", { staticClass: "fs20 fw6" }, [
              _vm._v(_vm._s(_vm.headerHeading)),
            ]),
          ])
        : _c("div", { staticClass: "col-12 no-padding" }, [
            _c("h2", { staticClass: "fs20 fw6" }, [
              _vm._v(_vm._s(_vm.headerHeading)),
            ]),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-8 no-padding" }, [
        _c(
          "div",
          { staticClass: "row justify-content-end text-right" },
          [
            _vm.tabs
              ? _vm._l(_vm.tabs.slice(0, 3), function (tab, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "col-lg-2 no-padding",
                      attrs: { title: tab },
                      on: { click: _vm.switchTab },
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "fs16 fw6 cursor-pointer tab",
                          class: index == 0 ? "active" : "",
                        },
                        [_vm._v(_vm._s(tab))]
                      ),
                    ]
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.scrollable && !(_vm.scrollable == "")
              ? [
                  _c(
                    "div",
                    { staticClass: "col-lg-2 no-padding switch-buttons" },
                    [
                      _c("div", { staticClass: "row justify-content-center" }, [
                        _c("h2", {
                          staticClass:
                            "col-lg-1 no-padding v-mr-20 fw6 cursor-pointer",
                          attrs: { title: "previous" },
                          domProps: { innerHTML: _vm._s("<") },
                          on: {
                            click: function ($event) {
                              return _vm.navigation("prev")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "h2",
                          {
                            staticClass:
                              "col-lg-1 no-padding fw6 cursor-pointer",
                            attrs: { title: "next" },
                            on: {
                              click: function ($event) {
                                return _vm.navigation("next")
                              },
                            },
                          },
                          [_vm._v(">")]
                        ),
                      ]),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            !(_vm.viewAll == "false" || _vm.viewAll == "") && _vm.viewAll
              ? [
                  _c("div", [
                    _c(
                      "a",
                      {
                        staticClass: "remove-decoration link-color",
                        attrs: {
                          href: _vm.viewAll,
                          title: `View all ${_vm.headerHeading} products`,
                        },
                      },
                      [
                        _c(
                          "h2",
                          { staticClass: "fs16 fw6 cursor-pointer tab" },
                          [_vm._v(_vm._s(_vm.__("home.view-all")))]
                        ),
                      ]
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }