var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.slicedCategories && _vm.slicedCategories.length > 0
    ? _c(
        "nav",
        {
          class: `sidebar ${_vm.addClass ? _vm.addClass : ""}`,
          attrs: { id: _vm.id },
          on: {
            mouseover: function ($event) {
              return _vm.remainBar(_vm.id)
            },
          },
        },
        [
          _c(
            "ul",
            { staticStyle: { "margin-bottom": "0" }, attrs: { type: "none" } },
            _vm._l(_vm.slicedCategories, function (category, categoryIndex) {
              return _c(
                "li",
                {
                  key: categoryIndex,
                  staticClass: "category-content cursor-pointer",
                  attrs: { id: `category-${category.id}` },
                  on: {
                    mouseout: function ($event) {
                      return _vm.toggleSidebar(_vm.id, $event, "mouseout")
                    },
                    mouseover: function ($event) {
                      return _vm.toggleSidebar(_vm.id, $event, "mouseover")
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      class: `category unset ${
                        category.children.length > 0 ? "fw6" : ""
                      }`,
                      attrs: { href: `${_vm.$root.baseUrl}/${category.slug}` },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "category-icon",
                          on: {
                            mouseout: function ($event) {
                              return _vm.toggleSidebar(
                                _vm.id,
                                $event,
                                "mouseout"
                              )
                            },
                            mouseover: function ($event) {
                              return _vm.toggleSidebar(
                                _vm.id,
                                $event,
                                "mouseover"
                              )
                            },
                          },
                        },
                        [
                          category.category_icon_url
                            ? _c("img", {
                                attrs: {
                                  src: category.category_icon_url,
                                  width: "20",
                                  height: "20",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "category-title" }, [
                        _vm._v(_vm._s(category["name"])),
                      ]),
                      _vm._v(" "),
                      category.children.length && category.children.length > 0
                        ? _c("i", {
                            staticClass: "rango-arrow-right pr15 float-right",
                            on: {
                              mouseout: function ($event) {
                                return _vm.toggleSidebar(
                                  _vm.id,
                                  $event,
                                  "mouseout"
                                )
                              },
                              mouseover: function ($event) {
                                return _vm.toggleSidebar(
                                  _vm.id,
                                  $event,
                                  "mouseover"
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  category.children.length && category.children.length > 0
                    ? _c("div", { staticClass: "sub-category-container" }, [
                        _c(
                          "div",
                          {
                            class: `sub-categories sub-category-${
                              _vm.sidebarLevel + categoryIndex
                            } cursor-default`,
                            on: {
                              mouseout: function ($event) {
                                return _vm.toggleSidebar(
                                  _vm.id,
                                  $event,
                                  "mouseout"
                                )
                              },
                              mouseover: function ($event) {
                                return _vm.remainBar(
                                  `sidebar-level-${
                                    _vm.sidebarLevel + categoryIndex
                                  }`
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "nav",
                              {
                                staticClass: "sidebar",
                                attrs: {
                                  id: `sidebar-level-${
                                    _vm.sidebarLevel + categoryIndex
                                  }`,
                                },
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.remainBar(
                                      `sidebar-level-${
                                        _vm.sidebarLevel + categoryIndex
                                      }`
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  { attrs: { type: "none" } },
                                  _vm._l(
                                    category.children,
                                    function (subCategory, subCategoryIndex) {
                                      return _c(
                                        "li",
                                        {
                                          key: `${subCategoryIndex}-${categoryIndex}`,
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              class: `category sub-category unset ${
                                                subCategory.children.length > 0
                                                  ? "fw6"
                                                  : ""
                                              }`,
                                              attrs: {
                                                id: `sidebar-level-link-2-${subCategoryIndex}`,
                                                href: `${_vm.$root.baseUrl}/${category.slug}/${subCategory.slug}`,
                                              },
                                              on: {
                                                mouseout: function ($event) {
                                                  return _vm.toggleSidebar(
                                                    _vm.id,
                                                    $event,
                                                    "mouseout"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "category-icon",
                                                  on: {
                                                    mouseout: function (
                                                      $event
                                                    ) {
                                                      return _vm.toggleSidebar(
                                                        _vm.id,
                                                        $event,
                                                        "mouseout"
                                                      )
                                                    },
                                                    mouseover: function (
                                                      $event
                                                    ) {
                                                      return _vm.toggleSidebar(
                                                        _vm.id,
                                                        $event,
                                                        "mouseover"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  subCategory.category_icon_url
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: subCategory.category_icon_url,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "category-title",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(subCategory["name"])
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "ul",
                                            {
                                              staticClass: "nested",
                                              attrs: { type: "none" },
                                            },
                                            _vm._l(
                                              subCategory.children,
                                              function (
                                                childSubCategory,
                                                childSubCategoryIndex
                                              ) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: `${childSubCategoryIndex}-${subCategoryIndex}-${categoryIndex}`,
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        class: `category unset ${
                                                          subCategory.children
                                                            .length > 0
                                                            ? "fw6"
                                                            : ""
                                                        }`,
                                                        attrs: {
                                                          id: `sidebar-level-link-3-${childSubCategoryIndex}`,
                                                          href: `${_vm.$root.baseUrl}/${category.slug}/${subCategory.slug}/${childSubCategory.slug}`,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "category-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                childSubCategory.name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }